import React from 'react';
import { PageContentResponse } from 'application/repositories/pageContentRepository';
import { getFullImageUrl } from 'helpers/getFullImageUrl';

export class Site {
	private _host: string;
	private _culture: string;
	private _siteSettings?: PageContentResponse['root']['properties']['siteSettings'];
	private _root: PageContentResponse['root'];
	private _noindex :  PageContentResponse['root']['properties']['noIndex']
	public constructor(
		root: PageContentResponse['root'],
		host: string,
		culture: string,
		siteSettings?: PageContentResponse['root']['properties']['siteSettings'],
		noindex?: PageContentResponse['root']['properties']['noIndex']
	) {
		this._host = host;
		this._culture = culture;
		this._root = root;
		this._siteSettings = siteSettings;
		this._noindex = noindex;
	}

	get culture(): string {
		return this._culture;
	}

	get host(): string {
		return this._host;
	}
	get noIndex(): PageContentResponse['root']['properties']['noIndex']
	{
		return this._root.properties.noIndex	}
	get siteSettings(): PageContentResponse['root']['properties']['siteSettings'] {
		return this._siteSettings;
	}

	get root(): PageContentResponse['root'] {
		return this._root;
	}

	public getSettings(key: 'googleTagManager'): Umbraco.GoogleTagManager['content']['properties'];
	public getSettings(key: 'mapsSettings'): Umbraco.MapSettings['content']['properties'];
	public getSettings(key: 'newsletterSignup'): Umbraco.NewsletterSignup['content']['properties'];
	public getSettings(key: 'serviceNavigation'): Umbraco.ServiceNavigation['content']['properties'];
	public getSettings(key: 'languageBar'): Umbraco.LanguageBar['content']['properties'];
	public getSettings(key: 'registerBar'): Umbraco.RegisterBar['content']['properties'];
	public getSettings(key: 'profileBar'): Umbraco.ProfileBar['content']['properties'];
	public getSettings(key: 'registerOverlay'): Umbraco.RegisterOverlay['content']['properties'];
	public getSettings(key: 'searchResultsUrl'): Umbraco.SearchResultsUrl['content']['properties'];
	public getSettings(
		key:
			| 'googleTagManager'
			| 'mapsSettings'
			| 'newsletterSignup'
			| 'serviceNavigation'
			| 'languageBar'
			| 'registerBar'
			| 'profileBar'
			| 'registerOverlay'
			| 'searchResultsUrl',
	):
		| Umbraco.GoogleTagManager['content']['properties']
		| Umbraco.MapSettings['content']['properties']
		| Umbraco.NewsletterSignup['content']['properties']
		| Umbraco.ServiceNavigation['content']['properties']
		| Umbraco.LanguageBar['content']['properties']
		| Umbraco.RegisterBar['content']['properties']
		| Umbraco.ProfileBar['content']['properties']
		| Umbraco.RegisterOverlay['content']['properties']
		| Umbraco.SearchResultsUrl['content']['properties'] {
		if (!this.siteSettings) return null;
		return this.siteSettings.find((e) => e.documentType === key)?.content?.properties;
	}

	public getNavigationItems(currentPageId?: number): Models.NavigationItem[] {
		const currentPage = this._root?.header?.find(item => item.id === currentPageId);
		
		

		return this._root?.header?.reduce((filtered, headerItem) => {
			headerItem.properties.pageSettings.forEach((pageSetting) => {
				if (pageSetting.documentType === 'navigation' && !pageSetting.content.properties.umbracoNaviHide) {
					const { navigationTitle, icon, iconHover, activateBreadcrumb } = pageSetting?.content?.properties ?? {};

					filtered.push({
						title: navigationTitle ? navigationTitle : headerItem.name,
						url: headerItem?.url,
						state: currentPageId === headerItem?.id ? 'isActive' : '',
						icon: icon?.url && getFullImageUrl(icon?.url),
						iconHover: iconHover?.url && getFullImageUrl(iconHover?.url),
						activateBreadcrumb: activateBreadcrumb
					});
				}
			});

			return filtered;
		}, []);
	}
}

export const SiteContext = React.createContext<Partial<Site>>(new Site(null, 'localhost', 'en-US', []));
