import classNames from 'classnames';
import styles from './AboutSection.module.scss';
import { Heading, Paragraph, Picture, LinkButton, Container, Grid, GridCell } from 'ui/components';

export interface AboutSectionProps {
	className?: string;
	header?: string;
	moduleLink?: Umbraco.Link;
	text?: string;
	image: Umbraco.Image;
	styleRevert?: boolean;
	styleFlip?: boolean;
}

export const AboutSection: React.FC<AboutSectionProps> = ({ className, header, text, image, moduleLink, styleRevert, styleFlip }) => {

	const target = moduleLink?.target ||  moduleLink?.target == "" ?  moduleLink?.target: "_self" ; 
	return (
		<section
			className={classNames(
				styles.AboutSection,
				{ [styles.AboutSection___revert]: styleRevert },
				{ [styles.AboutSection___flip]: styleFlip },
				className,
			)}
		>
			<Container width="Standard">
				<Grid wrap className={styles.AboutSection_grid}>
					<GridCell desktopWidth="50" className={styles.AboutSection_contentWrapper}>
						<Heading className={styles.AboutSection_heading} headingLevel="h3">
							{' '}
							{header}{' '}
						</Heading>
						{text && <Paragraph className={styles.AboutSection_text}>{text}</Paragraph>}
						<div className={styles.AboutSection_buttonWrapper}>
							{moduleLink && (
								<LinkButton className={styles.AboutSection_link} url={moduleLink.url} style="secondary" target={target}>
									{moduleLink.name}
								</LinkButton>
							)}
						</div>
					</GridCell>
					<GridCell desktopWidth="50" className={styles.AboutSection_imageWrapper}>
						{image && (
							<span className={styles.AboutSection_mediabox}>
								<Picture
									relativeUrl={image?.relativeUrl}
									properties={image.properties}
									focalPoint={image.focalPoint}
									aspectRatio={0.79}
									sizes="(min-width: 960px) 50vw, 100vw"
								/>
							</span>
						)}
					</GridCell>
				</Grid>
			</Container>
		</section>
	);
};
