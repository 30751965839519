import { createContext, useEffect, useState } from 'react';
import { Profiles, getProfiles } from 'application/repositories/profilesRepository';
import { getCMSHostname } from 'helpers/getCMSHostname';
import { useSession } from 'next-auth/react';
import { GetContactResult,getContact } from 'application/adapters/contacts/contactsAdapter';
export const ProfileContext = createContext<{profile:Profiles, branches:GetContactResult}>(null);

export const ProfileProvider = ({ children }) => {
	const [profile, setProfile] = useState<Profiles>(null);
	const [branches, setBranches] = useState<GetContactResult>(null);
	const { status } = useSession();

	useEffect(() => {
		if (status === 'authenticated') {
			getProfiles(getCMSHostname()).then((profile) => {
				setProfile(profile);
			});
		} else if (status === 'unauthenticated') {
			setProfile(null);
		}
	}, [status]);


	useEffect(() => {
		getContact(getCMSHostname())
			.then((response) => {
				setBranches(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	const value = {profile, branches}

	return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
};
